.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline .react-datepicker-popper {
  position: fixed !important;
}

.react-calendar-timeline .chakra-slide {
  position: relative !important;
}

.react-calendar-timeline .rct-header-root {
  position: relative;
  z-index: 90;
  height: 60px;
}

.rct-header-root > :not(.rct-calendar-header) {
  width: 0px !important;
}

.react-calendar-timeline .rct-calendar-header {
  width: 100% !important;
  /* position: fixed; */
}

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-top: -60px;
  position: absolute;
  min-height: 100% !important;
  width: 100% !important;
}

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
  min-height: 100% !important;
  width: 100% !important;
}

.react-calendar-timeline .rct-items .chakra-popover__popper {
  z-index: 90;
}

.react-calendar-timeline .rct-item:hover {
}

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
}
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  right: 0;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid var(--chakra-colors-border-muted);
  height: 100% !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: var(--chakra-colors-background-tint2);
  opacity: 0.75;
}

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  box-sizing: border-box;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd:nth-child(2) {
  background: var(--chakra-colors-background-default);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: transparent;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even:first-child {
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: var(--chakra-fontSizes-sm);
  color: var(--chakra-colors-text-muted);
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  justify-content: flex-start;
}

.react-calendar-timeline .rct-dateHeader-primary span {
  position: sticky;
  left: 0rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.75rem;
  font-size: var(--chakra-fontSizes-lg);
  font-weight: var(--chakra-fontWeights-semibold);
  color: var(--chakra-colors-text-muted);
}
