.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--chakra-colors-border-default);
  background-color: var(--chakra-colors-background-default);
  color: var(--chakra-colors-text-default);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  position: absolute !important;
  top: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--chakra-colors-common-primary);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--chakra-colors-common-primaryTransparent);
}

.react-datepicker__navigation--next {
  border-left-color: var(--chakra-colors-common-primary);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--chakra-colors-common-primaryTransparent);
}

.react-datepicker__header {
  background-color: var(--chakra-colors-background-tint2);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--chakra-colors-border-default);
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: var(--chakra-colors-background-default);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--chakra-colors-text-default);
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: var(--chakra-colors-border-default);
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: var(--chakra-colors-background-tint2);
}

.react-datepicker__month {
  background-color: var(--chakra-colors-background-default);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--chakra-colors-background-tint2);
}

.react-datepicker__day {
  color: var(--chakra-colors-text-default);
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-background-tint1);
}

.react-datepicker__day--disabled {
  color: var(--chakra-colors-text-muted) !important;
  opacity: 0.5;
}

.react-datepicker__day--disabled:hover {
  background: transparent !important;
}

.react-datepicker__day-name {
  color: var(--chakra-colors-text-default);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-common-primary);
  font-weight: normal;
  color: var(--chakra-colors-text-opposite);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--chakra-colors-common-primaryDark);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--chakra-colors-text-muted);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--chakra-colors-text-default);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: transparent;
}
.react-datepicker-popper {
  z-index: 2;
}
