/* Overrides for classes provided by react-draft-wysiwyg */

.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.rdw-editor-wrapper {
  height: 100%;
  overflow: hidden;
}
.rdw-editor-toolbar {
  min-height: 100px;
}
.rdw-editor-main {
  overflow: scroll;
  /* height: calc(100% - 100px); */
}

.rdw-editor-main h1,
.rdw-editor-main h2,
.rdw-editor-main h3,
.rdw-editor-main h4,
.rdw-editor-main h5,
.rdw-editor-main h6 {
  font-weight: bold;
}
