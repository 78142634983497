/* Mimic the input style from Chakra-UI for the react-datepicker input */

.label-date-picker > .react-datepicker__input-container > input {
  width: 100%;
  min-width: 0;
  outline: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 10px;
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  background-color: transparent;
}
