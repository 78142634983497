.msg-container::-webkit-scrollbar {
  display: none;
}

.msg-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rce-container-mlist.message-list {
  flex: 0;
}

.rce-mlist {
  max-height: 350px;
  /* background-color: #f8f9fb; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.fs-message-list-container .rce-mlist {
  max-height: calc(90vh - 9em);
}

.rce-container-mbox {
  padding-left: 20px;
  overflow: visible;
  display: flow-root;
  position: relative;
}

.rce-container-mbox.margin-bottom {
  margin-bottom: 7px;
}

.rce-container-mbox.failed-message .rce-mbox {
  cursor: pointer;
}

.rce-container-mbox.failed-message .rce-mbox::after {
  content: 'failed to send - click to retry';
  position: absolute;
  bottom: -14px;
  right: 3px;
  font-size: 10px;
  color: #c56a6a;
}

.rce-container-mbox.failed-message .rce-mbox {
  background-color: #dcaeae;
}

.rce-container-mbox.is-sending .rce-mbox {
  background-color: #ccd4d0;
}

.rce-container-mbox .spinner {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.rce-mbox {
  position: relative;
  margin-right: 12px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.chakra-ui-dark .rce-mbox {
  background-color: #2b2f3b;
}

.rce-mbox.rce-mbox-right {
  color: #148556;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  background-color: #e9fcf4;
  border-radius: 10px;
  margin-right: 10px;
}

.rce-mbox.rce-mbox-right .rce-mbox-time {
  color: #72E9B7;
}

.rce-mbox.rce-mbox-right .rce-mbox-status path {
  fill: currentColor;
}

.chakra-ui-dark .rce-mbox.rce-mbox-right {
  color: #72e9b7;
  background-color: #148556;
}

.chakra-ui-dark .rce-mbox.rce-mbox-right .rce-mbox-time {
  color: #22DD8F;
}

.rce-mbox .rce-mbox-body {
  margin-bottom: 10px;
}

.rce-mbox .rce-mbox-body + div > svg,
.rce-mbox .rce-mbox-body + svg {
  visibility: hidden;
}

.rce-mbox-body .rce-mbox-text .emoji {
  width: 14px;
  display: inline;
  transform: translate(0px, -3px);
  margin-top: 5px;
  margin-right: 3px;
}

.rce-mbox-body .rce-mbox-text .emoji:only-child {
  width: 22px;
}

.rce-mbox-body .rce-mbox-text:after {
  display: none;
}

.rce-mbox-body .rce-mbox-text a {
  color: #0F5FF0;
  font-weight: 600;
}

.chakra-ui-dark .rce-mbox-body .rce-mbox-text a {
  color: #ffffff;
  font-weight: 700;
}

.rce-mbox-body .rce-mbox-text a:hover {
  opacity: 0.75;
}

.rce-mbox .rce-mbox-time {
  display: flex;
  color: #c5c1c1;
  bottom: -18px;
  right: -6px;
}

.rce-mbox-right .rce-mbox-time {
  color: white;
}

.rce-mbox .rce-avatar-container {
  position: absolute;
  left: -40px;
  top: 0;
  border-radius: 30px;
}

.rce-mbox .rce-avatar-container img {
  object-fit: cover;
}

.rce-avatar-letter-background {
  background-color: transparent !important;
}

.rce-mbox .rce-mbox-status path {
  fill: #c5c1c1;
}

.rce-mbox-right .rce-mbox-status path {
  fill: white;
}

.message-list-container > div::-webkit-scrollbar {
  /* width: 0px;
  background: transparent; make scrollbar transparent */
  display: none;
}
